@use 'colors' as *;
@use 'mixins' as *;
@use 'variables' as *;

// Comment out the background-color for the footer containers 
// until we standardize all pages on a single template

.system-footer-container {
  // background: $text_black;
}

.s-lib-public-body .public-footer-container {
  // background-color: $text_black;
  text-align: center;
}

.s-lib-public-body #s-lib-footer-public,
.s-lib-footer,
#s-lib-footer-public {
  border-top: none;
  margin: 0 auto;
  font-size: 100%;
  background-color: $text_black;
  color: $text_footer_gray;
  padding: 45px 0 0 0;
}

.s-lib-public-body #s-lib-footer-public,
.s-lib-footer,
#s-lib-footer-public,
#s-lib-footer-login-link, 
#s-lib-footer-support-link,
.public-footer-container {
  a {
    color: $text_footer_dark_gray;
    text-decoration: underline;
    &:hover {
      color: $text_footer_white;
    }
  }
}

.footer, .system-footer-container, .public-footer-container {
  .s-lg-text-greyout {
    color: $text_footer_gray;
  }
}

.bold, .s-lg-bold, .s-lg-book-title {
  font-size: 100%;
}

.public-footer-container {
  margin: 0 auto;
  & > div.container {
    background-color: $text_black;
    padding: 10px 0 45px 15px;
  }
  @include max-width($bp_small_page_heading){
    .container {
      margin: 0 auto;
    }
  }
}
