$nyu_purple: #57068C;

$border_focus: #3dbbdb;
$border_solid_gray: #e7ebef;

$text_blue: #0170B2;
$text_black: #1C2127;
$text_footer_gray: #acb6c2;
$text_footer_dark_gray: #b1b1b1;
$text_footer_white: #d8dee5;
$text_dark_gray: #4a4f55;

$background_black: #22272d;