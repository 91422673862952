@use 'variables' as *;

#s-lib-bc .breadcrumb {
  font-size: $font_size_base;
  font-weight: $weight_normal;
}

#s-lib-bc-list {
  margin-left: 0;
}
