@use 'colors' as *;

@mixin focusOutline{
  -webkit-box-shadow: inset 0 0 0 2px $border_focus;
  box-shadow: inset 0 0 0 2px $border_focus !important;
  outline: none;
}

@mixin antialias{
  -webkit-font-smoothing: antialiased;
}

// ****************************************************************
// * RESPONSIVE MIXINS
// ****************************************************************
@mixin respond-to($media) {
  @media only screen and ($media) { @content; }
 }
 
 @mixin min-width($media) {
  @media only screen and (min-width: $media) { @content; }
 }
 
 @mixin max-width($media) {
  @media only screen and (max-width: $media) { @content; }
 }
 
 @mixin max-height($media) {
  @media only screen and (max-height: $media) { @content; }
 }
 
 @mixin min-height($media) {
  @media only screen and (min-height: $media) { @content; }
 }