// ****************************************************************
// * BREAKPOINTS
// ****************************************************************
$bp_collapsed_nav: 930px;
$bp_stacked_sidebar: 1030px;

// Headings
$bp_medium_page_heading: $bp_stacked_sidebar;
$bp_small_page_heading: 768px;
$bp_mobile_page_heading: 585px;

// Body content
$bp_mobile_body: $bp_mobile_page_heading;
$bp_stacked_map: 840px;
$bp_stacked_tables: 640px;
$bp_stacked_block_50: 730px;
$bp_stacked_block_33: 730px;
$bp_downsized_block_25: 730px;
$bp_stacked_block_25: 500px;

// Forms
$bp_downsized_forms: 730px;
$bp_mobile_forms: $bp_mobile_page_heading;

// * Families:
// ********************************
$family_icon: 'SS NYULibraries';
// 18px+ | Book, Medium, Bold, Black:
$family_nyu_perstare: 'NYUPerstare', Helvetica, Arial, sans-serif;
$family_nyu_perstare_italic: 'NYUPerstareItalic', Helvetica, Arial, sans-serif;
$family_nyu_perstare_condensed_italic: 'NYUPerstareCondensedItalic', Helvetica, Arial, sans-serif;

// 18px+ | Book, Medium, Bold:
$family_nyu_perstare_condensed: 'NYUPerstareCondensed', Helvetica, Arial, sans-serif;
// 9-18px | Light (+ italic), Book (+ italic), Medium (+ italic), Bold:
$family_nyu_perstare_ssm: 'NYUPerstare SSm', 'NYUPerstare SSm', Helvetica, Arial, sans-serif;


// * Weights:
// ********************************
$weight_light:  300;
$weight_regular: 375;
$weight_normal: 400;
$weight_medium: 500;
$weight_bold:   700;
$weight_black:  800;

// * Sizes:
// ********************************
$font_size_base: 14px;
$font_size_large: 18px;       // eg. {{ page.body }}
$font_size_medium: 16px;      // eg. Subheading, Header Nav.
$font_size_small: 12px;       // eg. "Posted on {{ post.date }}"

$font_size_base_33: $font_size_base - 1;
