#s-lg-guide-list-controls,
#s-lg-hp-nav-bottom form > span,
#s-lc-new-reservation-checkin,
#s-lc-new-reservation-checkout {
	display: none !important;
}

// Hide the "Additional Info" button on the database pages
button.collapsible.az-toggle {
 display: none !important;
}

//   Hide "Librarian Review" content from public display on individual database pages
#s-lg-az-cols .col-md-12.center h2.mb-3:nth-of-type(2),
#s-lg-az-cols .col-md-12.center h2.mb-3:nth-of-type(2) + .mt-2.mb-2 {
    display: none;
}

// Hide external link icons on Databases A-Z page only
.az-layout i.fa-external-link {
  display: none;
}
