@use 'colors' as *;
@use 'variables' as *;

// Buttons
button,
.btn,
.btn-primary,
form input[type='submit'], {
  background-image: none;
  &:hover, &:active, &:focus {
    text-decoration: underline;
  }
}

button,
button.btn-primary,
form button,
form .btn,
form .btn-info,
.s-lg-hp-btn-section {
  border-color: $nyu_purple;
  // These chained pseudo-classes all have to be overridden , oy
  &, &:hover, &:hover:active, &:active, &:focus, &:active:focus {
    background-color: $nyu_purple;
    color: white;
    border-color: $nyu_purple;
  }
}

.s-lg-index-nav-btn button.btn {
  background-color: none !important;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  font-size: $font_size_base;
  height: auto;
  padding: auto;
}

// Hack until we can change the actual inline content of the HP AZ search box
#s-lg-embedded-search-widget-1523468968820 button,
#s-lg-embedded-search-widget-1462306134927 button,
#s-lg-embedded-search-widget-1581027136944 button {
  color: white !important;
}

.btn-default:hover {
  background-color: inherit;
}
