@use 'colors' as *;
@use 'mixins' as *;
@use 'variables' as *;

.az-layout{

  .well {
    margin-top: 10px;
  }

  #alert-heading {
    color: $text_black;
  }

  #bobcat_tabs .nav-pills {
    & > li > a {
      background-color: whitesmoke !important;
      text-align: center !important; 
    }
    & > li.active > a, & > li > a:hover, & > li > a:focus {
      background-color: #e8e8e8 !important;
      outline: 0 !important;
      border-bottom: 0 !important;
      font-weight: normal !important;
      border-radius: 0;
    }
    & > li.active > a {
      color: $text_black;
    }
  }

  #ebscohostCustomSearchBox div.form-group,
  #ebscohostCustomSearchBox div.input-group {
    width: 100%;
  }
  #ebscohostsearchtext {
    width: 100%;
  }

  .arch-ebs-info h4 {
    font-size: 100%;
  }

  .btn-link[disabled=disabled],
  button.s-lg-text-ghost {
    &, &:hover {
      color: $text_black;
    }
  }

  .s-lib-header h1 {
    margin-bottom: 0.67em;
  }

  .arch-citationlinker {
    margin: 10px 0;
  }

  .form-horizontal .control-label {
    text-align: left;
  }
  
}

.az-guide {
  #s-lg-tabs-container, #s-lg-guide-tabs-title-bar {
    display: none;
  }
  h1 {
    margin: 0 0 .67em 0;
  }
}


.az-layout .s-lg-az-result-keywords,
.az-layout .s-lg-label-lib-review,
.az-layout #s-lib-public-header-desc,
.az-layout #s-lg-guide-search-box,
.az-layout #s-lg-guide-name,
.az-layout #s-lib-bc-guide {
  display: none;
}

.az-layout .s-lg-label-more-info button {
  font-size: 1.0em;
}  

.az-layout .s-lg-content-more-info {
  font-size: 1.0em;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.table > tbody > tr > td {
  border-top: 0;
  border-bottom: 1px solid #ddd;
}

#s-lg-embedded-search-widget-1462306134927 .s-lg-form-control {
  width: 70% !important;
}

.arch_big_button a {
  background-color: #57068c;
  padding: 20px 0;
  text-align: center;
  border-radius: 10px;
  letter-spacing: 1px;
  display: inline-block !important;
  font-size: 23px !important;
  letter-spacing: 1px !important;
  line-height: 25px !important;
}
.arch_big_button a, .arch_big_button a:hover, .arch_big_button a:active, .arch_big_button a:visited {
  color: white;
  text-decoration: none;
}

.databases_by_subject_button.arch_big_button {
  text-align: center;
  a {
    width: 270px;
    padding: 15px 0;
  }
}

.arch_big_button a {
  margin-bottom: 20px;
  width: 100%;
}

.homepage .databases_by_subject_button.arch_big_button {
  text-align: left;
}

.az-layout-subject-column {
  width: 45%;
}

.az-layout-subject-column:nth-child(2) {
  margin-left: 10%;
}

@include max-width($bp_small_page_heading) {
  .az-layout-subject-column {
    width: 100% !important;
  }
}

