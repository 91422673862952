@use 'colors' as *;

header {
	&.header {
		background-color: $nyu_purple;
	}
	div.logo {
		margin: 0;
		padding: 39px 0;
		display: block;
		a {
			display: block;
		}
	}
	& > div {
		margin: 0 auto;
	}
}